import React, { useState } from 'react'
import { primaryCategory } from '../../../config/configListing';
import { parse } from '../../../util/urlHelpers';
import { createResourceLocatorString } from '../../../util/routes';
import { Button } from '../../../components';

import css from "./ProfileSearchForm.module.css";

const SortOptions = [
    // These are default sort options
    { option: 'createdAt', label: 'Sort By: Newest' },
    { option: '-createdAt', label: 'Sort By: Oldest' },
    { option: '-price', label: 'Sort By: Lowest Price' },
    { option: 'price', label: 'Sort By: Highest Price' },
];


const ProfileSearchForm = (props) => {
    const { routes, history, params, intl } = props;
    const searchParanms = typeof window != "undefined" ? parse(window.location.search) : {};
    const { sort = "", pub_primaryCategory = "", keywords = "" } = searchParanms;
    // sort=-createdAt , keywords=new , pub_primaryCategory
    const [querySearchParams, setQuerySearchParams] = useState({ sort, pub_primaryCategory, keywords });


    const onApplyFilter = (updatedSearchParams) => {
        setQuerySearchParams(updatedSearchParams);
        const newSearchParams = {};
        for (const key in updatedSearchParams) {
            if (updatedSearchParams[key] && updatedSearchParams[key].trim()) {
                newSearchParams[key] = updatedSearchParams[key];
            }
        }


        if (newSearchParams.keywords && newSearchParams.keywords.length < 2) {
            const { keywords, ...rest } = newSearchParams;
            return history.push(createResourceLocatorString('ProfilePage', routes, { ...params }, { ...rest }));
        } else {
            return history.push(createResourceLocatorString('ProfilePage', routes, { ...params }, newSearchParams));
        }
    }

    const onResetFilter = () => {
        setQuerySearchParams({ sort: "", pub_primaryCategory: "", keywords: "" });
        return history.push(createResourceLocatorString('ProfilePage', routes, { ...params }, {}));
    }

    // primaryCategory
    return (
        <div className={css.formWrapper}>
            <div className={css.formRow}>
                <select
                    name='pub_primaryCategory'
                    value={querySearchParams.pub_primaryCategory}
                    onChange={(e) => {
                        const newSearchParams = { ...querySearchParams, pub_primaryCategory: e.currentTarget.value };
                        return onApplyFilter(newSearchParams)
                    }}>
                    <option key="categoryPlaceholder" value={""}>{intl.formatMessage({ id: 'ProfileSearchForm.categoryPlaceholder' })}</option>
                    {primaryCategory.map((st, index) => <option key={st.option + index} value={st.option}>{st.label}</option>)}
                </select>

                <select
                    name='sort'
                    value={querySearchParams.sort}
                    onChange={(e) => {
                        e.preventDefault();
                        const newSearchParams = { ...querySearchParams, sort: e.currentTarget.value };
                        return onApplyFilter(newSearchParams)
                    }}>
                    {SortOptions.map((st, index) => <option key={st.option + index} value={st.option}>{st.label}</option>)}
                </select>
                <Button
                    type='button'
                    className={css.submitButton}
                    onClick={(e) => {
                        e.preventDefault();
                        onResetFilter();
                    }}>
                    {intl.formatMessage({ id: 'ProfileSearchForm.resetFilter' })}
                </Button>
            </div>

            <div className={css.inputSearch}>
                <input
                    value={querySearchParams.keywords}
                    placeholder={intl.formatMessage({ id: 'ProfileSearchForm.searchPlaceholder' })}
                    onChange={(e) => {
                        setQuerySearchParams({ ...querySearchParams, keywords: e.target.value })
                    }}
                    onBlur={(e) => {
                        if (e.target.value && e.target.value.length && keywords == e.target.value) {
                            return
                        } else {
                            return onApplyFilter(querySearchParams)
                        }
                    }}
                    onKeyUp={(e) => {
                        if (e.key == "Enter") {
                            const newSearchParams = { ...querySearchParams, keywords: e.target.value };
                            return onApplyFilter(newSearchParams)
                        }

                    }}
                />
            </div>
        </div>
    )
}

export default ProfileSearchForm;
